:root {
  --primary-color: #d4af37; /* Warm beige background */
  --secondary-color: #4caf50; /* Green for text and hover */
  --header-text-color: #012d15; /* Dark color for header text */
  --brand-color: #d4af37; /* Gold for brand text */
  --navbar-background-color: #ffffff; /* Dark gray for navbar */
  --section-background-color: #ffffff; /* Dark gray for sections */
  --section-text-color: #4caf50; /* White text in sections */
  --hover-color: #4caf50; /* Gold on hover */
  --service-background-color: #2dff8f; /* Background for service items */
}

/* Global Styles */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;
  background-color: var(--section-background-color);
  color: var(--secondary-color);
}

.header {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #f0b80056;
  background-image: url(../src/Asset/images/hotelpic.jpg);
    background-repeat: inherit;
  object-fit: cover;
  margin-top: -6vh;
  height: 90vh;

  background-size: cover;
  padding: 40px 20px;
  /* border-radius: 8px;   */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header h1 {
  font-size: 10vh;
  font-weight: bolder;
  color: var(--header-text-color);
}

.navbar-brand {
  color: var(--brand-color);
  display: flex;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-text-color);
  padding: 15px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 10vh;
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links a {
  color: var(--brand-color);
  font-weight: bolder;
  text-decoration: none;
  font-size: larger;
  transition: color 0.3s, transform 0.3s;
}

.navbar-links a:hover {
  color: var(--hover-color);
  transform: scale(1.05);
}

.navbar-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--brand-color);
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px 0;
  gap: 1vw;
}

.carousel img {
  min-width: 40%;
  height: 30em;
  border-radius: 8px;
  scroll-snap-align: start;
  transition: transform 0.3s;
}
.about {
  padding: 50px;
}
.services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about,
.gallery,
.services,
.welcome,
.testimonials {
  background-color: var(--section-background-color);
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: var(--section-text-color);
}

.gallery h2 {
  font-size: 7vh;
  text-align: center;
  font-weight: bold;
}

.s-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .s-sec2 {
    height: 40%;
    width: 40%;
  }

  .s-sec1 {
    display: flex;
    justify-content: center;
    margin-left: 5vh;
    align-items: center;

    ul {
      font-size: 5vh;
      cursor: pointer;
    }
  }
}

.a-sec {
  display: flex;
  align-items: center;
  gap: 2vh;
 
  .abt{
   
    padding: 3vh 0;

  p {
    color: var(--header-text-color);
   
    font-size: 3vh;
  }
 }
}

.about h2 {
  font-size: 7vh;
  font-weight: bolder;
  color: var(--brand-color);
  text-align: center;
}

hr {
  border: 1px solid var(--hover-color);
}
.logo-img {
  height: 12vh;
}
.about-image
 {
  width: 100vw;
  height: 70vh;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.accommodations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accommodation-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.accommodation-item {
  width: 30%;
  margin: 20px;
  background-color: var(--header-text-color);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.accommodation-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

/* Responsive styles */

@media (max-width: 1024px) {
  .accommodation-item {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .accommodation-item {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .accommodation-item {
    width: 100%;
    margin: 10px;
  }
  .accommodation-item img {
    height: 100px;
  }
}
h2 {
  color: var(--brand-color);
  margin-bottom: 15px;
}

.services h2 {
  font-size: 7vh;
  font-weight: bolder;
}

.services ul {
  list-style-type: none;
  padding: 0;
}

.services li {
  background: var(--header-text-color);
  color: white;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.services li:hover {
  background: var(--hover-color);
}

.welcome h2 {
  font-weight: bold;
  font-size: x-large;
}

.testimonials h2 {
  font-weight: bold;
  font-size: x-large;
}

.footer {
  text-align: center;
  background-color: var(--header-text-color);
  color: white;
  padding: 20px;

  h2 {
    font-weight: bold;
    font-size: 6vh;
  }
  a {
    font-size: 1em;
  }
}

.cont-info {
  display: flex;
  justify-content: center;
  gap: 4vh;
  align-items: center;
}

.cont-info a {
  margin: 20px 0;
  display: flex;
  color: var(--brand-color);
  align-items: center;
}

.cont-info svg {
  margin-right: 10px;
  height: 3vh;
  width: 3vh;
}
.dining {
  padding: 50px 30px;
  background-color: #f7f7f7;
  border-radius: 8px;
}
.dining h2 {
  font-size: 7vh;
  padding-left: 1vh;
  text-align: center;
  font-weight: bold;
}
.dining-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dining-image {
  width: 50%;
  padding: 20px;
}

.dining-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.dining-text {
  width: 50%;
  padding: 20px;
}

.dining-text p {
  font-size: 18px;
  color: #012d15;
  margin-bottom: 20px;
}

.dining-text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dining-text li {
  font-size: 18px;
  color: #012d15;
  margin-bottom: 10px;
}

.dining-text li::before {
  content: "\2022";
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-right: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy", sans-serif;
}

#page2 {
  width: 100%;
}

.move {
  background-color: var(--header-text-color);
  display: flex;
  padding: 1vw 0;
  overflow: hidden;
}

.maro {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 1vw;
  width: 50%; /* Ensure full width for each item */
}

.maro h1 {
  font-size: 3vw;
  font-family: "Courier New", Courier, monospace;
  color: var(--brand-color); /* Make text visible */
}

.maro img {
  height: 3vw;
}

/* Responsive design */
@media only screen and (max-width: 768px) {
  .move {
    flex-direction: row-reverse;
    gap: 0.5vh;
  }

  .maro {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .maro h1 {
    font-size: 2vw;
  }

  .maro img {
    height: 2vw;
  }
}

@media only screen and (max-width: 480px) {
  .move {
    padding: 7vw 0;
  }

  .maro h1 {
    font-size: 6vw;
  }

  .maro img {
    height: 6vw;
  }
}

@media (max-width: 480px) {
  .advanced-image img {
    height: 200px;
  }

  .advanced-text p {
    font-size: 16px;
  }

  .advanced-text li {
    font-size: 16px;
  }
}

/* Drawer Styles */
.navbar-links.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  background-color: #061a0f;
  padding: 1vh;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: flex-start;
  }
  .navbar-toggle {
    display: block;
  }
  .header{
    height: 50vh;
    width: 100%;
  }
  .header h1 {
    font-size: 2em;
    text-align: center;
  }
  .logo-img {
    height: 6vh;
  }
  .carousel img {
    width: 80vw;
    height: 25vh;
  }
  .dining {
    padding: 50px 0;
  }
  .dining-content {
    flex-direction: column;
  }

  .dining-image {
    width: 100%;
    padding: 20px;
  }

  .dining-text {
    width: 100%;
    padding: 20px;
  }
  .footer {
    a {
      font-size: 0.85em;
    }
  }
  .cont-info {
    display: grid;
  }
  .navbar-links {
    flex-direction: column;
    margin-top: 10px;
    gap: 0;
    display: none;
  }
  .a-sec {
    display: grid;
  }
  .s-sec {
    display: grid;

    .s-sec2 {
      width: 100%;
      height: 100%;
    }

    .s-sec1 {
      width: 90%;
      height: 100%;
      ul {
        margin-left: -5vh;
      }
    }
  }
  .navbar-links li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .about {
    padding: 15px;
  }
  .services h2 {
    text-align: center;
  }
  .about-image,
  .services-image,
  .contact-image {
    margin: 10px 0;
  }
}
