/* WhatsAppButton.css */
.whatsapp-button {
    display: inline-block;
    position:fixed;
    padding: 10px 20px;
    background-color: #25d366; /* WhatsApp green color */
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #128c7e; /* Darker shade on hover */
  }
  